import DatePicker from 'vue2-datepicker';
import api from "@/_helper/api-services.js";

export default {
    name: "gerenciarPedidos",
    data() {
        return {
            lang: {
                days: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
                months: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez']
            },
            formSearch: {
                dataInicial: this.dataMenos30(),
                dataFinal: new Date(),
                quantidadePorPagina: 15,
                pagina: 1
            },
            totalPaginas: 1,
            fields: [
                { key: "idAnuncio", label: "#", class: "d-none" },
                { key: "titulo", label: "Título", class: "text-center" },
                { key: "descricao", label: "Descrição", class: "text-center" },
                { key: "urlVideo", label: "Vídeo", class: "text-center" },
                // { key: "dataPedido", label: "Data", class: "text-center", formatter: val => { if (!val) return ""; return `${new Date(val).toLocaleDateString()} ${new Date(val).toLocaleTimeString()}` } },
                { key: "acoes", label: "#" }
            ],
            items: [],
            showIconsToChangePage: false,
            idAnuncio: null,
            modalConfirm: false,
            modalConfirmDelete: false
        };
    },
    watch: {
        async 'formSearch.pagina'() {
            await this.getPedidos();
        },
    },
    computed: {
        totalPagesToDisplay() {
            if (this.totalPaginas <= 5) {
                this.showIconsToChangePage = false;
                return this.totalPaginas;
            }
            else {
                this.showIconsToChangePage = true;

                let paginasSobrando = this.totalPaginas - this.formSearch.pagina;

                if (paginasSobrando == 0) {
                    let retorno = [];
                    for (let i = this.totalPaginas - 2; i <= this.totalPaginas; i++)
                        retorno.push(i);
                    return retorno;
                } else if (paginasSobrando == this.totalPaginas - 1) return [1, 2, 3];
                else return [this.formSearch.pagina - 1, this.formSearch.pagina, this.formSearch.pagina + 1];
            }
        }
    },
    components: {
        DatePicker
    },
    mounted() {
        this.getAnunciantes();
    },
    methods: {
        dataMenos30() {
            var result = new Date();
            result.setDate(result.getDate() + -30);
            return result;
        },
        getAnunciantes() {
            this.$loading(true);
            this.items = [];

            api.get("/adverts/advertsToApprove").then(success => {
                if (success.data)
                    this.items = success.data;
                // this.totalPaginas = success.data.totalPages;
                // this.items = success.data.pedidosFiltrados;
                // alert('caiu aqui');
                this.$loading(false);
            }, error => {
                if (error.response && error.response.data && error.response.data.errors)
                    error.response.data.errors.forEach(e => {
                        this.$snotify.error(e);
                    });
                // else
                //     this.$snotify.error("An unexpected error has occurred");

                this.$loading(false);
            })

        },
        alterarPagina(pagina) {
            this.formSearch.pagina = pagina;
        },
        abriModalAprovarPagamento(idAnuncio) {
            this.idAnuncio = idAnuncio;
            this.modalConfirm = true;
        },
        async confirmAprovacao() {
            this.$loading(true);
            await api.get(`/adverts/approve/${this.idAnuncio}`).then(
                success => {
                    this.$snotify.success("Anúncio aprovado com sucesso");
                    this.modalConfirm = false;
                    this.getAnunciantes();
                },
                error => {
                    if (error.response && error.response.data && error.response.data.message)
                        this.$snotify.error(error.response.data.message);
                    // else this.$snotify.error("An unexpected error has occurred");
                }
            );
            this.$loading(false);
        },
        async confirmReprovacao() {
            this.$loading(true);
            await api.get(`/adverts/reprove/${this.idAnuncio}`).then(
                success => {
                    this.$snotify.success("Anúncio reprovado com sucesso");
                    this.modalConfirmDelete = false;
                    this.getAnunciantes();
                },
                error => {
                    if (error.response && error.response.data && error.response.data.message)
                        this.$snotify.error(error.response.data.message);
                    // else this.$snotify.error("An unexpected error has occurred");
                }
            );
            this.$loading(false);
        }
    }
}